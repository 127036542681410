<template>
  <div>
    <div class="e-breadcrumb">品牌管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">品牌编号:</span>
              <el-input placeholder="品牌编号" size="small" style="width:265px" v-model="brandNo" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">品牌名称:</span>
              <el-input placeholder="品牌名称" size="small" style="width:265px" v-model="brandName" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">创建日期:</span>
              <el-date-picker
                v-model="cDate"
                size="small"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              <div>
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                  </el-button>
                  <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="130" class="th-l">品牌编号</th>
                <th width="130" class="th-l">品牌名称</th>
                <th width="130" class="th-l">创建时间</th>
                <th width="180" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.brandNo}}</span></td>
                <td class="td-l">{{row.brandName}}</td>
                <td class="td-l"><span>{{row.createTime}}</span></td>
                <td class="td-l">
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                  <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addBbiBrandSuccess" />
    <Edit  v-if="editVisible"  :editVisible="editVisible" @closeEdit="closeEdit" @editSuccess="editBbiBrandSuccess" :editItem="editItem" />

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delBbiBrand">确 定</el-button>
            </span>
    </el-dialog>

  </div>
</template>

<script>
  import Insert from './insert'
  import Edit from './edit'

  export default {
    data() {
      return {
        cDate:[],
        brandNo: '',
        brandName: '',
        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        addVisible: false,
        editVisible: false,
        delVisible: false,
        editItem: {},
      }
    },
    async created() {
      this.searchEvent();
    },
    methods: {
      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let staDate = '';
        let endDate = '';
        if (this.cDate && this.cDate.length > 0) {
          staDate = this.cDate[0]
          endDate = this.cDate[1]
        }
        let data = {
          id: this.id,
          brandNo: this.brandNo,
          brandName: this.brandName,
          staDate:staDate,
          endDate:endDate,
          isDel: '0',
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getBbiBrandForPage", data)
        this.list = res.dataSet.list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pages: res.dataSet.pages
        }
      },

      addEvent() {
        this.addVisible = true;
      },

      editEvent(index) {
        this.editItem = this.list[index]
        this.editVisible = true;
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除BbiBrand
      async delBbiBrand() {
        let data = {
          id: this.editItem.id
        };
        let res = await this.$post("/admin/delete_bbiBrand", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

      closeInsert() {
        this.addVisible = false;
      },

      closeEdit() {
        this.editVisible = false;
      },

      addBbiBrandSuccess() {
        this.closeInsert();
        this.searchEvent();
      },

      editBbiBrandSuccess() {
        this.closeEdit();
        this.searchEvent();
      }
    },
    components: {
      Insert,
      Edit
    }
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }
  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
    .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
