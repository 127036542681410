<template>
  <el-dialog title="新增" :close-on-click-modal="false"  width="600px" :visible.sync="addVisible" :before-close="closeEvent">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="品牌编号:" required>
        <el-input v-model="brandNo" style="width: 435px" placeholder="请输入品牌编号" maxlength="32" show-word-limit></el-input>
      </el-form-item>
      <el-form-item  size="small" label="品牌名称:" required>
        <el-input size="small"  v-model="brandName" style="width: 435px" placeholder="请输入品牌名称" maxlength="64" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
            <el-button  size="small" @click="closeEvent">取 消</el-button>
            <el-button  size="small" type="primary" @click="saveBbiBrand">确 定</el-button>
        </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        brandNo: '',
        brandName: '',
      }
    },
    props: {
      addVisible: Boolean
    },
    methods: {
      closeEvent() {
        this.$emit('closeInsert')
      },
      async saveBbiBrand() {
        let data = {
          brandNo: this.brandNo,
          brandName: this.brandName,
        };
        if (!this.brandNo) {
          this.$message({
            showClose: true,
            message: '请输入品牌编号',
            type: 'error'
          });
          return
        }
        if (!this.brandName) {
          this.$message({
            showClose: true,
            message: '请输入品牌名称',
            type: 'error'
          });
          return
        }

        let res = await this.$post("/admin/save_bbiBrand", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$emit('addSuccess')
        }else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
