<template>
    <el-dialog title="编辑"  :close-on-click-modal="false"  :visible.sync="editVisible" width="600px" :before-close="closeEvent">
        <el-form ref="form"  label-width="100px">
                         <el-form-item label="品牌编号:" size="small" required>
                            <el-input v-model="item.brandNo"  style="width: 435px" placeholder="请输入品牌编号" maxlength="32" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="品牌名称:" size="small"  required>
                            <el-input v-model="item.brandName" style="width: 435px" placeholder="请输入品牌名称" maxlength="64" show-word-limit></el-input>
                        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeEvent">取 消</el-button>
                <el-button size="small" type="primary" @click="saveBbiBrand">确 定</el-button>
            </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                show: false,
                item: this.editItem,
                seaportId:this.editItem.seaportId
            }
        },
        props: {
            editItem: Object,
            seaportItem: Array,
            editVisible: Boolean
        },
        methods: {
            closeEvent () {
                this.$emit('closeEdit')
            },
            async saveBbiBrand() {
                let data = {
                    id:this.item.id,
                  brandNo:this.item.brandNo,
                  brandName:this.item.brandName,
                };
                   if(!this.item.brandNo) {
                       this.$message({
                           showClose: true,
                           message:'请输入品牌编号',
                           type: 'error'
                       });
                       return
                   }
                   if(!this.item.brandName) {
                       this.$message({
                           showClose: true,
                           message:'请输入品牌名称',
                           type: 'error'
                       });
                       return
                   }
                let res = await this.$post("/admin/save_bbiBrand" ,data)
                if (res.code == 200){
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.$emit('editSuccess')
                }else{
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            }
        }
    }
</script>

